import { addScaleCorrector } from 'framer-motion';
import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { MotionValue, motion } from "framer-motion";

export default ({ imgSrc, title, desc, color, height, glow }) => {
    return (
        <div style={{
            height: '340px',
            width: '300px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                height: '340px',
                width: '260px',
                display: 'flex',
                // justifyContent: 'space-around',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer',
                fontFamily: 'Montserrat, sans-serif',
            }}>
                {/* IMAGE */}
                <div 
                class="carousel-image"
                style={{
                    borderRadius: '10px',
                    width: '98%',
                    height: '180px',
                    padding: '10px',
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // backgroundColor: color ? 'RGB(180, 200, 243)' : 'RGB(200, 220, 230)',
                    // boxShadow: "rgba(255, 255, 255, 0.075) 0px 0px 56px",
                    
                    // Testing
                    backgroundColor: 'rgba(240, 241, 244, 0.2)',
                    boxShadow: glow,
                }}>
                    <img width='100%' src={imgSrc} height= {height} style={{
                        borderRadius: "8px",
                        opacity: 0.9,
                    }}/>
                </div>
                {/* TITLE */}
                <div 
                class="carousel-word"
                style={{
                    fontWeight: 600,
                    fontSize: '24px',
                    marginTop: '25px',
                    marginBottom: '10px'
                }}>
                    {title}
                </div>

                {/* DESC */}
                <div 
                class="carousel-word"
                style={{
                    fontWeight: 600,
                    fontSize: '15px',
                    textAlign: 'center',
                }}>
                    {desc}
                    {/* Empower Education, Both Onsite and Online. */}
                </div>
            </div>
        </div>
    )
}