import React, { Component } from "react";
import { MotionValue, motion } from "framer-motion";
import Slider from "react-slick";
import PictureItem from '../PictureItem';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Carousel.css";
import { MotionConfig } from "framer-motion";
import typeking_home from '../../assets/images/typeking/typeking_home.gif'
import typeking_lobby from '../../assets/images/typeking/typeking_lobby.gif'
import typeking_type from '../../assets/images/typeking/typeking_type.gif'
import typeking_death from '../../assets/images/typeking/typeking_death.gif'
import typeking_firebase from '../../assets/images/typeking/typeking_firebase.png'

export default class CenterMode extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
    };
    return (
      <div>
        <motion.div
          initial={{ scale: 0.85, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.85, opacity: 0 }}
          transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
        >
          <Slider 
          {...settings}
          >
            <div>
                <PictureItem imgSrc={typeking_home} height="400px"/>
            </div>
            <div>
              <PictureItem imgSrc={typeking_lobby} height="400px" desc="TypeKing features a unique loading lobby screen that players see when they are matched with their opponents. Once in the game, a countdown is implemented to signal the commencement of the typing match."/>
            </div>
            <div>
              <PictureItem imgSrc={typeking_type} height="400px" desc="TypeKing integrates top-tier graphics to visualize player actions, like attacking and being attacked. This enriches the interactive experience, making users feel a deeper connection to their on-screen avatars."/>
            </div>
            <div>
              <PictureItem imgSrc={typeking_death} height="400px" desc="Furthermore, it features personalized stats and a customized menu that allows players to monitor their own progress and achievements. These player-centric metrics are also a crucial element in the game's matchmaking process."/>
            </div>
            <div>
              <PictureItem imgSrc={typeking_firebase} height="400px" desc="TypeKing leverages a robust Firebase backend database to safeguard player data. This architecture is designed to facilitate real-time query retrievals with an impressively low latency of around 20 milliseconds. With this, rapid data exchange, it ensures players enjoy a seamless, high-quality gaming experience"/>
            </div>
          </Slider>
        </motion.div>
      </div>
    );
  }
}