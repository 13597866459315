import React from "react";
import { motion } from 'framer-motion';
import { MotionConfig } from "framer-motion";
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
// import nightBackground from '../assets/black_night.jpg'
import nightStars from '../assets/black.jpeg';
import Planet from '../components/Planet';

export default () => {

    // Sun
    let Sun = "inset 0 0 30px 0 #fff, inset 20px 0 30px #ffd400, inset -20px 0 30px red, inset 20px 0 300px #ff40d6, inset -20px 0 300px #e3ff4b, 0 0 50px #fff, -10px 0 100px #fbff00, 10px 0 80px #F86"

    return (
        <MotionConfig viewport={{ once: true }}>
            <Planet
                x={900} 
                y={250}
                glow={Sun}
            />
            <Box sx={{
                width: "100vw",
                height: "100vh",
                // backgroundImage: `url(${nightBackground})`,
                backgroundImage: `url(${nightStars})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
            }}>
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100vw",
                        height: "100vh",
                        flexDirection: 'column',
                        color: '#f3f7ff',
                        opacity: 0.9,
                        fontFamily: 'Montserrat, sans-serif',
                    }}>
                        <motion.div
                            initial={{ y: 36, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
                            style={{
                                margin: '0px',
                                padding: '5px',
                                fontWeight: '570',
                                fontSize: '25px'
                            }}
                        >
                            {'Hello, World!'}
                        </motion.div>
                        <motion.div 
                            initial={{ y: 36, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, duration: 0.6, damping: 12 }}
                            style={{
                                fontWeight: '710',
                                fontSize: '55px',
                                margin: '0px',
                                padding: '8px'
                            }}
                        >
                            <Typewriter
                                options={{
                                    strings: [
                                    "My Name Is Adrian Si.",
                                    "I'm a Software Engineer.",
                                    ],
                                    autoStart: true,
                                    loop: true,
                                    delay: 100,
                                }}
                            /> 
                        </motion.div>
                        <motion.div
                            initial={{ y: 36, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, duration: 0.6, damping: 12 }}
                            style={{
                                margin: '0px',
                                padding: '15px',
                                fontWeight: '250',
                                fontSize: '20px',
                            }}
                        >
                            based in Sydney, Australia.
                        </motion.div>
                    </Box>
            </Box>
        </MotionConfig>
    )
}
