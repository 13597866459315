import { Box } from '@mui/material';
import { easeInOut, motion } from 'framer-motion';

export default ({x, y, planet, glow, size, blur}) => {

    let dim = size ? size : "50px"
    let blurEffect = blur ? `blur(${blur}px)` : 'blur(0.6px)'

    return (
        <Box
            sx={{
                position: 'absolute',
                width: dim,
                height: dim,
                // border: '1px solid blue',
                borderRadius: '50%',
                left: x,
                top: y,
            }} 
        >
            <motion.div
                initial={{ opacity: 0 }} 
                animate={{ opacity: 1 }}
                transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
            >
            <Box sx={{
                position: 'absolute',
                width: dim,
                height: dim,
                margin: 'auto',
                borderRadius: '10px',
            }}>
                <motion.div 
                    initial={{ x: 0, y: 0}}
                    animate={{ x: -20, y: 0 }}
                    transition={{
                        x: {
                            delay: 0,
                            type: 'tween',
                            ease: 'easeInOut',
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 2, 
                        },
                        boxShadow: {
                            delay: 0,
                            type: 'tween',
                            ease: 'easeInOut',
                            repeat: Infinity,
                            repeatType: 'reverse',
                            duration: 3, 
                        }
                    }}
                >
                    <Box sx={{
                        position: 'absolute',
                        zIndex: 100,
                        borderRadius: '50%',
                        width: dim,
                        height: dim,
                        // Sun 
                        boxShadow: glow,
                        // boxShadow: 'inset 0 0 30px 0 #fff, inset 20px 0 30px #ffd400, inset -20px 0 30px red, inset 20px 0 300px #ff40d6, inset -20px 0 300px #e3ff4b, 0 0 50px #fff, -10px 0 100px #fbff00, 10px 0 80px #F86' 

                        // Planet Image
                        ...(planet && {
                            backgroundImage: `url(${planet})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: '200%',
                            filter: blurEffect,
                        })

                        // Pluto
                        // boxShadow: 'inset 0 0 30px 0 #fff, inset 20px 0 30px #003ce6, inset -20px 0 30px #001a66, inset 20px 0 300px #0059b3, inset -20px 0 300px #002b80, 0 0 50px #fff, -10px 0 100px #0033cc, 10px 0 80px #004de6' 
                    }}>
                    </Box>
                </motion.div>
            </Box>
            </motion.div>
        </Box>
    )
}