import React, { Component } from "react";
import { MotionValue, motion } from "framer-motion";
import Slider from "react-slick";
import PictureItem from '../PictureItem';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Carousel.css";
import { MotionConfig } from "framer-motion";
import echo_home from '../../assets/images/echo/echo_home.png'
import echo_workshops from '../../assets/images/echo/echo_workshops.png'
import echo_conferences from '../../assets/images/echo/echo_conferences.png'
import echo_about from '../../assets/images/echo/echo_about.png'

export default class CenterMode extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
    };
    return (
      <div>
        <motion.div
          initial={{ scale: 0.85, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.85, opacity: 0 }}
          transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
        >
          <Slider 
          {...settings}
          >
            <div>
                <PictureItem imgSrc={echo_home} desc="Echo is a non-profit, youth-driven organization focused on enhancing financial and economic literacy among young individuals in regional Australia. Their interactive workshops and conferences aim to empower these youths with the knowledge to make better-informed financial and economic decisions." link="https://www.echoaus.org.au/"/>
            </div>
            <div>
              <PictureItem imgSrc={echo_about} desc="Echo is dedicated to enhancing economic and financial literacy among young Australians through workshops and conferences. They provide a platform for students to understand the importance of basic economic education and connect with national leaders. Echo's aim is to empower youth with transformative experiences that shape their outlook." link="https://www.echoaus.org.au/"/>
            </div>
            <div>
              <PictureItem imgSrc={echo_workshops} desc="Echo provides enriching experiences that help young individuals understand the significance of financial and economic literacy through inspirational and relatable talks. They strive to make economics and finance accessible and comprehensible to all students through engaging and interactive learning opportunities." link="https://www.echoaus.org.au/"/>
            </div>
            <div>
              <PictureItem imgSrc={echo_conferences} desc="Echo's conferences serve as a connecting link, bringing young Australians into dialogue with the country's eminent leaders in economics and finance. They organize one-day events, inviting high schools to participate in sessions led by prominent figures from government and industry sectors." link="https://www.echoaus.org.au/"/>
            </div>
          </Slider>
        </motion.div>
      </div>
    );
  }
}