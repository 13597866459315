import React from "react";
import { motion } from 'framer-motion';
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
import CenterMode from '../Carousels/FuseFrenzyCarousel';
import nightBackground from '../../assets/black_night.jpg'
import Sun from '../Planet';
import BasicTabs from '../TabPanels/FuseFrenzyTabPanel';
import "../Carousel.css";
import Planet from "../Planet";

export default ({ isVisible, handleCarouselClick }) => {

    // Create item handle function
    const handleItemClick = (event) => {
        if (event.target === event.currentTarget) {
            handleCarouselClick('FuseFrenzy')
        }
    }

    let jupiterGlow = "0 0 40px #fff, -10px 0 90px #ff7f00, 10px 0 50px #8b4513, inset 0 0 30px 0 #fff, inset 20px 0 30px #ffaa60, inset -20px 0 30px #6e3d28, inset 20px 0 300px #ffcc99, inset -20px 0 300px #6e3d28, 0 0 50px #fff, -10px 0 100px #ffaa60, 10px 0 80px #6e3d28";

    return (
        <div 
            onClick={handleItemClick}
            style={{
                color: '#fff',
                fontFamily: 'Montserrat, sans-serif',
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(43, 43, 43, 0.667)",
                zIndex: 100,
                pointerEvents: isVisible ? 'auto' : 'none',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.25s ease-in-out'
        }}>
            <div className="no-scrollbar" style={{
                width: "clamp(50%, 1200px, 95%)",
                height: "clamp(60%, 700px, 95%)",
                overflow: "hidden scroll",
                backgroundImage: `url(${nightBackground})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                borderRadius: "16px",
                boxShadow: "rgba(244, 164, 96, 0.35) 0px 0px 56px",
                position: 'relative',
            }}>
                <div className='no-scrollbar' style={{
                    display: "flex",
                    padding: "100px",
                    overflowY: 'scroll',
                    flexDirection: "column",
                }}>
                    {/* PLANET */}
                    <Planet
                        x={550}
                        y={70}
                        glow={jupiterGlow}
                        size={100}
                    />
                    
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // overflow: "hidden scroll",
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/* TITLE */}
                        <div style={{
                            marginTop: '100px',
                            fontSize: '48px',
                            fontWeight: 620,
                            lineHeight: '150%'
                        }}>
                            Fuse Frenzy
                        </div>
                        {/* DESC */}
                        <div style={{
                            fontSize: '16px',
                            marginTop: '20px',
                            lineHeight: '150%',
                            width: '70%',
                            textAlign: 'center'
                        }}>
                            "Fuse Frenzy" is an innovative game that I developed for a university project, demonstrating my deep understanding and application of Java. It stands as an exhibit of my adeptness in object-oriented programming, featuring a well-structured and crafted class hierarchy. This creation was instrumental in my academic success, as it significantly contributed to my impressive overall score of 90+ in the course. 
                        </div>
                        {/* CAROUSEL */}
                        <motion.div
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
                        >
                            <div style={{
                                width: '890px',
                                height: '620px',
                                opacity: 1,
                                transform: 'none',
                            }}>
                                <CenterMode />
                            </div>
                        </motion.div>
                        {/* Tab Panel */}
                        <div style={{
                            marginTop: '20px'
                        }}>
                            <BasicTabs />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
