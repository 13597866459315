import { Box } from '@mui/material';
import { motion } from 'framer-motion';

export default () => {
    return (
        <Box sx={{
            color: 'white',
            display: 'flex',
            position: 'absolute',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '98px',
            gap: '400px'
        }}>
            <motion.div
                initial={{ width: '0px '}}
                animate={{ width: '450px '}}
                transition={{ type: "spring", stiffness: 140, damping: 12 }}
            >
                <Box 
                    sx={{
                        // width: '450px',
                        height: '10px',
                        borderTop: '3px solid rgb(38, 38, 38)',
                    }}
                />
            </motion.div>
            <motion.div
                initial={{ width: '0px '}}
                animate={{ width: '450px '}}
                transition={{ type: "spring", stiffness: 140, damping: 12 }}
            >
                <Box 
                    sx={{
                        // width: '450px',
                        height: '10px',
                        borderTop: '3px solid rgb(38, 38, 38)',
                    }}
                />
            </motion.div>
        </Box>
    )
}