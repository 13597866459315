import { Box } from '@mui/material';
import { useState } from 'react';

export default ({onClick, selected, children, sx}) => {

    const [ hovered, setHovered ] = useState(false);
    
    return (
        <Box 
            onClick={onClick}
            onMouseEnter={()=>setHovered(true)}
            onMouseLeave={()=>setHovered(false)}
            sx={{
                display: 'flex',
                flexDirection: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontWeight: 650,
                fontSize: '15px',
                color: (hovered || selected) ? '#f3f7ff' : '#989899',
                transition: 'all ease-in 200ms',
            }}
        >
            {children}
        </Box>
    )
}