import { Box } from "@mui/material";
import { MotionValue, motion } from "framer-motion";

export default ({children}) => {
    return (
        <Box sx={{
            width: '365px',
            height: '77px',
            // border: '1px solid red',
            fontWeight: 750,
            fontSize: '32px',
            color: '#f3f7ff',
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
            alignItem: 'center',
        }}>
            <motion.div 
                initial={{ y: 36, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'absolute',
                    height: '100px',
                    width: '200px',
                    top: 17,
                    left: 80,
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                {children}
            </motion.div>
            <motion.div 
                initial={{ y: 36, opacity: 0 }}
                animate={{ y: 0, opacity: 0.2 }}
                exit={{ y: -10, opacity: 0 }}
                transition={{ type: 'spring', bounce: 0.6, stiffness: 120, damping: 12 }}
                style={{
                    color: '#8e9a9d',
                    // maxWidth: '100%',
                    // maxHeight: '100%',
                    position: 'absolute',
                    zIndex: 11,
                    fontSize: '65px',
                    fontFamily: 'Montserrat, sans-serif',
                }}
            >
                {children.toUpperCase()}
            </motion.div>
        </Box>
    )
}