import React from "react";
import { motion } from 'framer-motion';
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
import CenterMode from '../Carousels/TypeKingCarousel';
import nightBackground from '../../assets/black_night.jpg'
import Sun from '../Planet';
import BasicTabs from '../TabPanels/AdvanceTabPanel';
import "../Carousel.css";
import Planet from "../Planet";

export default ({ isVisible, handleCarouselClick }) => {

    // Create item handle function
    const handleItemClick = (event) => {
        if (event.target === event.currentTarget) {
            handleCarouselClick('TypeKing')
        }
    }

    let neptuneGlow = "0 0 40px #fff, -10px 0 90px #0077ff, 10px 0 50px #ff7f00, inset 0 0 30px 0 #fff, inset 20px 0 30px #3399ff, inset -20px 0 30px #ff5500, inset 20px 0 300px #66b3ff, inset -20px 0 300px #ff8533, 0 0 50px #fff, -10px 0 100px #3399ff, 10px 0 80px #ff5500";

    return (
        <div 
            onClick={ handleItemClick }
            style={{
                color: '#fff',
                fontFamily: 'Montserrat, sans-serif',
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(43, 43, 43, 0.667)",
                zIndex: 100,
                pointerEvents: isVisible ? 'auto' : 'none',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.25s ease-in-out'
        }}>
            <div className="no-scrollbar" style={{
                width: "clamp(50%, 1200px, 95%)",
                height: "clamp(60%, 700px, 95%)",
                overflow: "hidden scroll",
                backgroundImage: `url(${nightBackground})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                borderRadius: "16px",
                boxShadow: "rgba(135, 206, 250, 0.35) 0px 0px 56px",
                position: 'relative',
            }}>
                {/* PLANET */}
                <Planet
                    x={1000}
                    y={200}
                    glow={neptuneGlow}
                    size={60}
                />

                <div className='no-scrollbar' style={{
                    display: "flex",
                    padding: "100px",
                    overflowY: 'scroll',
                    flexDirection: "column",
                    // overflow: "auto", // Added this line
                    // scrollbarWidth: "none", // For Firefox
                    // msOverflowStyle: "none", // For Internet Explorer and Edge
                    // "&::-webkit-scrollbar": {
                    //     display: "none" // For Chrome, Safari and Opera
                    // },
                    // backgroundImage: "url(/static/media/blur2.abf9490….png)",
                    // backgroundSize: "110%",
                    // backgroundRepeatY: "no-repeat",
                    // backgroundPosition: "center top",
                }}>
                    
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // overflow: "hidden scroll",
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/* TITLE */}
                        <div style={{
                            marginTop: '100px',
                            fontSize: '48px',
                            fontWeight: 620,
                            lineHeight: '150%'
                        }}>
                            TypeKing
                        </div>
                        {/* DESC */}
                        <div style={{
                            fontSize: '16px',
                            marginTop: '20px',
                            lineHeight: '150%',
                            width: '70%',
                            textAlign: 'center'
                        }}>
                            TypeKing is an online interactive game I developed to cultivate touch typing skills through an enjoyable, interactive way. It aims to enhance productivity and digital literacy across all age groups, transforming typing from a task into an instinct. Committed to innovation, we offer a rewarding experience that fosters faster, confident communication in the digital world.
                        </div>
                        {/* CAROUSEL */}
                        <motion.div
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
                        >
                            <div style={{
                                width: '890px',
                                // marginTop: '20px',
                                height: '530px',
                                opacity: 1,
                                transform: 'none',
                            }}>
                                <CenterMode />
                            </div>
                        </motion.div>
                        {/* Tab Panel */}
                        <div>
                            <BasicTabs />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
