import { Box, Tooltip } from '@mui/material';
import { MotionConfig, motion } from 'framer-motion';
import { useNavigate, useLocation } from 'react-router-dom';
import Divider from './Divider';
import TextButton from './TextButton';

export default () => {

    const navigate = useNavigate();
    const location = useLocation();

    return (
        <Box>
            <Divider>

            </Divider>
            <Box sx={{
                width: '100%',
                height: '86px',
                zIndex: 10,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Box sx={{
                    fontFamily: 'Montserrat, sans-serif',
                    display: 'flex',
                    alignItems: 'center',
                    fontWeight: 470,
                    fontSize: '18px',
                    gap: '30px',
                    color: '#f3f7ff',
                }}>
                    <TextButton
                        onClick={()=>navigate("/")} 
                        selected={location.pathname==="/"}
                    >
                        <motion.div
                            whileTap={{ scale: 0.90 }}
                            whileHover={{ scale: 1.05 }}
                        >
                            Home
                        </motion.div>
                    </TextButton>
                    <TextButton
                        onClick={()=>navigate("/about")} 
                        selected={location.pathname==="/about"}
                    >
                        <motion.div
                            whileTap={{ scale: 0.90 }}
                            whileHover={{ scale: 1.05 }}
                        >
                            About
                        </motion.div>
                    </TextButton>
                    <TextButton
                        onClick={()=>navigate("/projects")} 
                        selected={location.pathname==="/projects"}
                    >
                        <motion.div
                            whileTap={{ scale: 0.90 }}
                            whileHover={{ scale: 1.05 }}
                        >
                            Projects
                        </motion.div>
                    </TextButton>
                    <TextButton
                        onClick={()=>navigate("/career")} 
                        selected={location.pathname==="/career"}
                    >
                        <motion.div
                            whileTap={{ scale: 0.90 }}
                            whileHover={{ scale: 1.05 }}
                        >
                            Career
                        </motion.div>
                    </TextButton>
                </Box>
            </Box>
        </Box>
    )
}