import { Box } from "@mui/material";
import Planet from '../components/Planet';
import Title from '../components/Title';
import nightBackground from '../assets/black_night.jpg'
import nightStars from '../assets/black.jpeg';
import { MotionValue, motion } from "framer-motion";
import img1 from './media/headshot.png';

export default () => {

    // Sun
    let Sun = "inset 0 0 30px 0 #fff, inset 20px 0 30px #ffd400, inset -20px 0 30px red, inset 20px 0 300px #ff40d6, inset -20px 0 300px #e3ff4b, 0 0 50px #fff, -10px 0 100px #fbff00, 10px 0 80px #F86"

    return (
        <>
            <Planet
                x={1200}
                y={100} 
                glow={Sun}
            />
            <Box sx={{
                width: "100vw",
                height: "100vh",
                backgroundImage: `url(${nightStars})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: "75%",
                    height: "75%",
                    // border: '1px solid blue',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '32px',
                    color: '#f3f7ff',
                    position: 'relative'
                }}>
                    <Title>
                        About Me
                    </Title>
                    {/* TEXT / PHOTO */}
                    <Box sx={{
                        height: '100%',
                        width: '100%',
                        // border: '1px solid red',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: '50px',
                    }}>
                        <Box sx={{
                            position: 'relative',
                            height: '100%',
                            width: '100%',
                            color: 'rgb(180, 180, 180)',
                            // color: '#74767a',
                            lineHeight: '160%',
                            fontSize: '14px',
                            fontWeight: 400,
                            fontSize: 13,
                            flex: 3,
                            fontFamily: 'Montserrat, sans-serif',
                            // Styling Span
                            '& span': {
                                color: 'white',
                                fontWeight: 550,
                            },
                            '& a': {
                                color: 'white',
                                fontWeight: 550,
                                textDecoration: "underline"
                            }
                        }}>
                            <motion.div 
                                initial={{ opacity: "0%" }}
                                animate={{ opacity: '100%' }}
                                transition={{ type: "spring", stiffness: 140, damping: 12 }} 
                            >
                                <div style={{
                                    position: 'absolute', 
                                    right: -10, 
                                    top: 0, 
                                    bottom: 0, 
                                    width: '1px', 
                                    backgroundColor: 'rgb(38, 38, 38)'
                                }}></div>
                            </motion.div>
                            <motion.div
                                initial={{ y: 36, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
                            >
                                {/* Introduction */}
                                Greetings! My journey into the intersection of health and technology was set in motion as a Biomedical Engineering and Computer Science undergraduate at the <span>University of Sydney</span>. Currently, I'm wearing the hat as the Software Coordinator at <a href="https://advanceau.com/" target="_blank">Advance</a>, where I've developed a comprehensive full stack web application. My mission is to leverage my dual expertise and bridge the gap between <span>health and technology</span> to develop solutions & products that will make a real impact. I am constantly learning and eager to <span>create innovative solutions</span> and contribute to the world around me.
                                <br/>
                                <br/>

                                {/* Academic */}
                                During my academic journey at the University of Sydney, I have consistently maintained a <span>High Distinction WAM | 4.0 GPA</span> as a distinguished <span>Dalyell Scholar</span>, and have been recognized with the prestigious <span>Sydney Scholars Award</span>. I am committed to maintaining this level of academic excellence and anticipate graduating with <span>First Class Honours</span>.
                                <br/>
                                <br/>

                                {/* Extra Curricular */}
                                In addition to my academic success, I'm actively involved and committed in professional university societies such as Sydney University Society of Medical Innovation <span>(SUSMI) - Vice President</span>, Sydney University Association of Biomedical Engineers <span>(SUABE) - Sponsorships</span> and Sydney Consulting Club <span>(SCC) - Case Squad</span>. Beyond university, I am also a <span>Student Ambassador</span> for the <span>Biomedical Subdivision of Engineers Australia</span>.
                                <br/>
                                <br/>

                                {/* Closing */}
                                My varied experiences have nurtured a well-rounded viewpoint on the intriguing intersection of health and technology. I deeply value and seek every opportunity to <span>grow, collaborate, & make a significant impact through my contributions</span>.
                            </motion.div>
                        </Box>
                        <Box sx={{
                            // border: '1px solid blue',
                            height: '100%',
                            width: '100%',
                            flex: 2,
                            marginLeft: '30px'
                        }}>
                            <motion.div
                                initial={{ y: 36, opacity: 0 }}
                                animate={{ y: 0, opacity: 1 }}
                                exit={{ y: -10, opacity: 0 }}
                                transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
                            >
                                <img src={img1} alt="head shot image" style={{width: "450px", borderRadius: "10px", boxShadow: "0px 10px 15px rgba(0, 0, 0, 0.1)"}}/>
                            </motion.div>
                        </Box>

                    </Box>
                </Box>
            </Box>
        </>
    )
}
