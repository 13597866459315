import React, { Component } from "react";
import { MotionValue, motion } from "framer-motion";
import Slider from "react-slick";
import PictureItem from '../PictureItem';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Carousel.css";
import { MotionConfig } from "framer-motion";
import fuse_frenzy_play1 from '../../assets/images/fuse_frenzy/fuse_frenzy_play1.gif'
import fuse_frenzy_play2 from '../../assets/images/fuse_frenzy/fuse_frenzy_play2.gif'
import fuse_frenzy_play3 from '../../assets/images/fuse_frenzy/fuse_frenzy_play3.gif'
import fuse_frenzy_play4 from '../../assets/images/fuse_frenzy/fuse_frenzy_play4.gif'
import fuse_frenzy_play5 from '../../assets/images/fuse_frenzy/fuse_frenzy_play5.gif'

export default class CenterMode extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
    };
    return (
      <div>
        <motion.div
          initial={{ scale: 0.85, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.85, opacity: 0 }}
          transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
        >
          <Slider 
          {...settings}
          >
            <div>
                <PictureItem imgSrc={fuse_frenzy_play1} height="600px"/>
            </div>
            <div>
              <PictureItem imgSrc={fuse_frenzy_play2} height="600px"/>
            </div>
            <div>
              <PictureItem imgSrc={fuse_frenzy_play3} height="600px"/>
            </div>
            <div>
              <PictureItem imgSrc={fuse_frenzy_play4} height="600px"/>
            </div>
            <div>
              <PictureItem imgSrc={fuse_frenzy_play5} height="600px"/>
            </div>
          </Slider>
        </motion.div>
      </div>
    );
  }
}