import * as React from 'react';
import { Box } from "@mui/material";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function BasicAccordion() {

    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };
  
  return (
    <div>
      <Accordion 
            expanded={expanded === 'panel1'}
            onChange={handleChange('panel1')}
            sx={{ background: '#333', color: '#fff', margin: '10px auto' }}
        >

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0px 10px',
                alignItems: 'center',
            }}>
                <Typography sx={{ fontWeight: 600 }}>Software Engineering intern @ ResMed</Typography>
                <Typography sx={{ fontSize: 14 }}>November 2023 - Present</Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '3px 8px 10px', margin: '0px 10px' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                margin: '0px 10px',
            }}>
                <Typography sx={{ color: '#aaacb2', fontSize: 14 }} dangerouslySetInnerHTML={{ __html: 'Sydney,&nbsp;&nbsp;Australia' }} />
                <Typography sx={{ color: '#ddd', marginTop: '10px', fontSize: 15 }}>
                Optimized verification and validation processes by creating a test automation platform in an ASP.NET environment, using C#, Python, and JavaScript, and integrating AWS services and Jenkins to enhance the scalability and reliability of testing workflows. Led initiatives in machine learning and AI to introduce cutting-edge development features, significantly boosting project outcomes and team efficiency through improved collaboration and technological innovation.
                </Typography>
                {/* SKILLS */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: '#ddd',
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontSize: 15,
                        fontWeight: 400
                    }} 
                >
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        JavaScript
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        C#
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        Python
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        AWS
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        ASP.NET
                    </Box>
                </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion 
            expanded={expanded === 'panel2'}
            onChange={handleChange('panel2')}
            sx={{ background: '#333', color: '#fff', margin: '10px auto' }}
        >

        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0px 10px',
                alignItems: 'center',
            }}>
                <Typography sx={{ fontWeight: 600 }}>Software Coordinator @ Advance</Typography>
                <Typography sx={{ fontSize: 14 }}>October 2022 - Present</Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '3px 8px 10px', margin: '0px 10px' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                margin: '0px 10px',
            }}>
                <Typography sx={{ color: '#aaacb2', fontSize: 14 }} dangerouslySetInnerHTML={{ __html: 'Sydney,&nbsp;&nbsp;Australia' }} />
                <Typography sx={{ color: '#ddd', marginTop: '10px', fontSize: 15 }}>
                Developed a comprehensive full-stack web application for Advance, incorporating a career tree, learning modules, and Q&A forums, utilising MongoDB, Express, React and NodeJS to provide a user-friendly experience for high school students seeking career guidance.
                </Typography>
                {/* SKILLS */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: '#ddd',
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontSize: 15,
                        fontWeight: 400
                    }} 
                >
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        JavaScript
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        React
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        Express
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        NodeJS
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        MongoDB
                    </Box>
                </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion 
            expanded={expanded === 'panel3'}
            onChange={handleChange('panel3')}
            sx={{ background: '#333', color: '#fff', margin: '10px auto' }}
        >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Box sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                margin: '0px 10px',
                alignItems: 'center',
            }}>
                <Typography sx={{ fontWeight: 600 }}>Full Stack Engineer @ Echo</Typography>
                <Typography sx={{ fontSize: 14 }}>June 2022 - October 2022</Typography>
            </Box>
        </AccordionSummary>
        <AccordionDetails sx={{ padding: '3px 8px 10px', margin: '0px 10px' }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                margin: '0px 10px',
            }}>
                <Typography sx={{ color: '#aaacb2', fontSize: 14 }} dangerouslySetInnerHTML={{ __html: 'Sydney,&nbsp;&nbsp;Australia' }} />
                <Typography sx={{ color: '#ddd', marginTop: '10px', fontSize: 15 }}>
                Led the design and development of Echo's user-friendly website using HTML, CSS, and JavaScript, benefiting over 500 students across 10 schools by enhancing their financial literacy, and successfully managing a team of software engineers and UX/UI designers, resulting in a 30% increase in user engagement.
                </Typography>
                {/* SKILLS */}
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-around',
                        alignItems: 'center',
                        color: '#ddd',
                        marginTop: '20px',
                        marginBottom: '10px',
                        fontSize: 15,
                        fontWeight: 400
                    }} 
                >
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        JavaScript
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        React
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        HTML
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        CSS
                    </Box>
                    <Box
                        sx={{
                            // border: '1px solid blue',
                            padding: '9px 12px',
                            borderRadius: '10px',
                            backgroundColor: '#2E2E2E',
                        }} 
                    >
                        Git
                    </Box>
                </Box>
            </Box>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}
