import { Box } from "@mui/material";
import Planet from '../components/Planet';
import Title from '../components/Title';
import nightBackground from '../assets/black_night.jpg'
import nightStars from '../assets/black.jpeg';
import { SwiperSlide } from 'swiper/react';
import { MotionValue, motion } from "framer-motion";
import img1 from './media/headshot.png';
import BasicAccordion from '../components/Accordions/Accordion'


export default () => {

    // Sun
    let Sun = "inset 0 0 30px 0 #fff, inset 20px 0 30px #ffd400, inset -20px 0 30px red, inset 20px 0 300px #ff40d6, inset -20px 0 300px #e3ff4b, 0 0 50px #fff, -10px 0 100px #fbff00, 10px 0 80px #F86"

    return (
        <>
            <Planet
                x={1200}
                y={100} 
                glow={Sun}
            />
            <Box sx={{
                width: "100vw",
                height: "100vh",
                backgroundImage: `url(${nightStars})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <Box sx={{
                    width: "75%",
                    height: "75%",
                    // border: '1px solid blue',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '32px',
                    color: '#f3f7ff',
                    position: 'relative'
                }}>
                    <Title sx={{
                        flex: 1
                    }}>
                        Career
                    </Title>
                    {/* ACCORDION */}
                    <Box sx={{
                        width: '70%',
                        marginTop: '70px',
                        flex: 3,
                    }}>
                        <motion.div
                            initial={{ y: 36, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -10, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }}
                        >
                            <BasicAccordion />
                        </motion.div>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
