import React from "react";
import { motion } from 'framer-motion';
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
import CenterMode from '../Carousels/EchoCarousel';
import nightBackground from '../../assets/black_night.jpg'
import Planet from '../Planet';
import BasicTabs from '../TabPanels/AdvanceTabPanel';
import "../Carousel.css";
import earth from '../../assets/images/planets/earth.jpg';

export default ({ isVisible, handleCarouselClick }) => {
    // Create item handle function
    const handleItemClick = (event) => {
        if (event.target === event.currentTarget) {
            handleCarouselClick('Echo')
        }
    }

    let earthGlow = "0 0 40px #fff, -10px 0 90px #0077be, 10px 0 50px #32cd32, inset 0 0 30px 0 #fff, inset 20px 0 30px #0077ff, inset -20px 0 30px #0055cc, inset 20px 0 300px #0094ff, inset -20px 0 300px #0066cc, 0 0 50px #fff, -10px 0 100px #0077ff, 10px 0 80px #0055cc";

    return (
        <div 
            onClick={handleItemClick}
            style={{
                color: '#fff',
                fontFamily: 'Montserrat, sans-serif',
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(43, 43, 43, 0.667)",
                zIndex: 100,
                pointerEvents: isVisible ? 'auto' : 'none',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.25s ease-in-out'
        }}>
            <div className="no-scrollbar" style={{
                width: "clamp(50%, 1200px, 95%)",
                height: "clamp(60%, 700px, 95%)",
                overflow: "hidden scroll",
                backgroundImage: `url(${nightBackground})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                borderRadius: "16px",
                // boxShadow: "rgba(255, 255, 255, 0.075) 0px 0px 56px",
                boxShadow: "rgba(70, 130, 180, 0.4) 0px 0px 56px",
                position: 'relative',
            }}>
                {/* PLANET */}
                <Planet
                    x={200}
                    y={100}
                    // planet={earth}
                    glow={earthGlow}
                    size={60}
                    blur={0.8}
                />

                <div className='no-scrollbar' style={{
                    display: "flex",
                    padding: "100px",
                    overflowY: 'scroll',
                    flexDirection: "column",
                    // overflow: "auto", // Added this line
                    // scrollbarWidth: "none", // For Firefox
                    // msOverflowStyle: "none", // For Internet Explorer and Edge
                    // "&::-webkit-scrollbar": {
                    //     display: "none" // For Chrome, Safari and Opera
                    // },
                    // backgroundImage: "url(/static/media/blur2.abf9490….png)",
                    // backgroundSize: "110%",
                    // backgroundRepeatY: "no-repeat",
                    // backgroundPosition: "center top",
                }}>
                    
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // overflow: "hidden scroll",
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/* TITLE */}
                        <div style={{
                            marginTop: '100px',
                            fontSize: '48px',
                            fontWeight: 620,
                            lineHeight: '150%',
                        }}>
                            Echo
                        </div>
                        {/* DESC */}
                        <div style={{
                            fontSize: '17px',
                            fontWeight: 500,
                            marginTop: '20px',
                            lineHeight: '150%',
                            width: '70%',
                            textAlign: 'center'
                        }}>
                            Leveraging my expertise in software development, particularly in the React framework, I created a website that has impacted over 500 students across 20 regional schools in NSW, enhancing their understanding of economics and finance. Additionally, I cultivated solid partnerships with engineering and customer service teams, collaboratively working to guarantee smooth integration and optimal user satisfaction across all platforms.
                        </div>
                        {/* CAROUSEL */}
                        <motion.div
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
                        >
                            <div style={{
                                width: '890px',
                                // marginTop: '20px',
                                height: '580px',
                                opacity: 1,
                                transform: 'none',
                            }}>
                                <CenterMode />
                            </div>
                        </motion.div>
                        {/* Tab Panel */}
                        <div>
                            <motion.div
                                initial={{ opacity: 0, scale: 0.85 }}
                                animate={{ opacity: 1, scale: 1 }}
                                transition={{ duration: 0.15, ease: 'easeInOut' }}
                            >
                                <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '760px', height: '120px', justifyContent: 'space-around', padding: '0px 20px', marginTop: '5px', fontWeight: 530}}>
                                    <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                        <div style={{minWidth: '6px'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                                </path>
                                            </svg>
                                        </div>
                                        Leading a diverse group of volunteers, I built a cohesive team culture for conducting career workshops, and demonstrated strong presentation skills by delivering engaging and informative career education sessions.
                                    </div>
                                    <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                        <div style={{minWidth: '6px'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                                </path>
                                            </svg>
                                        </div>
                                        Designed and constructed Echo’s user-friendly website using HTML, CSS, and JavaScript, serving over 500+ students and 10+ schools regionally to effectively aid in the financial / economic literacy of students, ensuring Echo’s success.
                                    </div>
                                    <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                        <div style={{minWidth: '6px'}}>
                                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                                <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                                </path>
                                            </svg>
                                        </div>
                                        Effectively led and coordinated small group of software engineers and UX/UI designers to ensure seamless integration and delivery of high-quality website, increasing outreach / user engagement by 30%.
                                    </div>
                                </div>
                            </motion.div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
