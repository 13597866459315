import React, { Component } from "react";
import { MotionValue, motion } from "framer-motion";
import Slider from "react-slick";
import PictureItem from '../PictureItem';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Carousel.css";
import { MotionConfig } from "framer-motion";
import advance_careers from '../../assets/images/advance/advance_careers.png'
import advance_home from '../../assets/images/advance/advance_home.png'
import advance_learning from '../../assets/images/advance/advance_learning.png'
import advance_qna from '../../assets/images/advance/advance_qna.png'
import advance_tree from '../../assets/images/advance/advance_tree.png'

export default class CenterMode extends Component {
  render() {
    const settings = {
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      speed: 500,
    };
    return (
      <div>
        <motion.div
          initial={{ scale: 0.85, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.85, opacity: 0 }}
          transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
        >
          <Slider 
          {...settings}
          >
            <div>
                <PictureItem imgSrc={advance_home} link="https://advanceau.com/"/>
            </div>
            <div>
              <PictureItem imgSrc={advance_careers} desc="Advance delivers comprehensive education on numerous career paths, empowering high school students to make informed choices and gain a clearer understanding of what lies ahead of them after high school" link="https://advanceau.com/"/>
            </div>
            <div>
              <PictureItem imgSrc={advance_learning} desc="Advance offers a platform where ambitious and curious students can continue to seek career advice even after workshops, fostering a supportive community through its forum. Here, they can exchange insights and receive guidance from both peers and experts alike." link="https://advanceau.com/"/>
            </div>
            <div>
              <PictureItem imgSrc={advance_qna} link="https://advanceau.com/"/>
            </div>
            <div>
              <PictureItem imgSrc={advance_tree} desc="
Advance provides an interactive career progression tree, enabling students to navigate through diverse career prospects and pathways. This feature empowers them to understand the necessary steps and potential outcomes associated with a specific career trajectory." link="https://advanceau.com/"/>
            </div>
          </Slider>

        </motion.div>
      </div>
    );
  }
}