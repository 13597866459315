import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Route, Routes, useLocation } from "react-router";
import { addScaleCorrector, AnimatePresence } from 'framer-motion';

import Home from './pages/Home'
import About from './pages/About'
import Career from './pages/Career'
import Projects from './pages/Projects'
import Navbar from './components/Navbar'
import AnimPopIn from './animations/AnimPopIn'


export default () => {

    const location = useLocation();

    return (
        <Box>
            <Navbar />
                <Routes key={location.key} location={location}>
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/career" element={<Career />} />
                    <Route path="/projects" element={<Projects />} />
                </Routes>
        </Box>
    )
}
