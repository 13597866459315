import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MotionValue, motion } from "framer-motion";

function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{
            p: 3,
            color: 'white',
            padding: '0px 20px' 
            }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const theme = createTheme({
    palette: {
      primary: {
        main: '#FFFFFF',
      },
      secondary: {
        main: '#11cb5f',
      },
    },
    components: {
        MuiTab: {
            styleOverrides: {
                root: { // this is for all buttons
                    '&:hover': {
                        color: 'white', // your hover color here
                    },
                },
            },
        },
    },
  });

  return (
    <ThemeProvider theme={theme}>
        <Box
            sx={{ display: 'flex', height: 224, flexGrow: 1}}
        >
            <Tabs
                orientation="vertical"
                variant="scrollable"
                value={value}
                onChange={handleChange}
                aria-label="Vertical tabs example"
                sx={{ 
                    borderRight: 1, 
                    borderColor: 'divider',
                    "& button": {
                        color: 'rgb(164, 164, 164)',
                        fontSize: "0.9 rem",
                        fontWeight: 800,
                        lineHeight: 1.25,
                        // maxWidth: '200px',
                        // minWidth: '90px',
                        width: '200px',
                        minHeight: '48px',
                        borderRadius: '4px 0px 0px 4px',
                        transition: 'all 200ms ease 0s',
                        // padding: '12px 16px',
                        cursor: 'pointer',
                        textDecoration: 'none',
                        appearance: 'none',
                        textTransform: 'none',
                    },
                    // "& button:hover": {backgroundColor: '#fff', opacity: 0.1, color: 'white'}
                }}
            >
                <Tab label="Object Oriented Programming" {...a11yProps(0)} />
                <Tab label="Critical Thinking & Problem Solving" {...a11yProps(1)} />
            </Tabs>
            <TabPanel value={value} index={0}>
                <motion.div
                    initial={{ opacity: 0, scale: 0.85 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.15, ease: 'easeInOut' }}
                >
                    <div style={{display: 'flex', gap: '34px', width: '760px', padding: '0px 20px', lineHeight: 1.2, height: '120px', marginTop: '5px'}}>
                        {/* COLUMN */}
                        <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '50%', fontWeight: 530}}>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px', fontFamily: "font-family: Roboto, Helvetica, Arial, sans-serif"}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Proficiency in Object Oriented Programming principles in Java
                            </div>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Designed comprehensive UML (Unified Modelling Language) diagram
                            </div>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Utilised data structures, exception handling, and I/O operations
                            </div>
                        </div>
                        {/* COLUMN */}
                        <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '50%', fontWeight: 530}}>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Design / implement algorithms for game mechanics / logic
                            </div>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Implement unit / end-to-end testing to ensure game functionality
                            </div>
                            <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                                <div style={{minWidth: '6px'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                        <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                        </path>
                                    </svg>
                                </div>
                                Managed memory usage to ensure optimal game performance (e.g garbage collector)
                            </div>
                        </div>
                    </div>

                </motion.div>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <motion.div
                    initial={{ opacity: 0, scale: 0.85 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.15, ease: 'easeInOut' }}
                >
                    <div style={{display: 'flex', flexDirection: 'column', gap: '12px', width: '760px', height: '120px', justifyContent: 'space-around', padding: '0px 20px', marginTop: '5px', fontWeight: 530}}>
                        <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                            <div style={{minWidth: '6px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                    </path>
                                </svg>
                            </div>
                            Exhibited critical thinking and problem-solving skills while developing the game's mechanics and addressing technical challenges.
                        </div>
                        <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                            <div style={{minWidth: '6px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                    </path>
                                </svg>
                            </div>
                            Demonstrated excellent attention to detail during the intricate process of designing the UML and class structure.
                        </div>
                        <div style={{display: 'flex', fill: 'white', gap: '8px', fontSize: '13px'}}>
                            <div style={{minWidth: '6px'}}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" width="6px">
                                    <path d="M73 39c-14.8-9.1-33.4-9.4-48.5-.9S0 62.6 0 80V432c0 17.4 9.4 33.4 24.5 41.9s33.7 8.1 48.5-.9L361 297c14.3-8.7 23-24.2 23-41s-8.7-32.2-23-41L73 39z">
                                    </path>
                                </svg>
                            </div>
                            Showcased effective time management and organization skills in meeting project deadlines and balancing multiple tasks.
                        </div>
                    </div>
                    
                </motion.div>
            </TabPanel>
        </Box>
        
    </ThemeProvider>
  );
}