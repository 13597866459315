import { addScaleCorrector } from 'framer-motion';
import React from 'react';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { MotionValue, motion } from "framer-motion";

export default ({ imgSrc, desc, link, height }) => {
    return (
        <div style={{
            width: '898px',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            {/* IMAGE */}
            <div class="image-hover" style={{
                margin: 'auto',
                width: '820px',
                height: '420px',
                borderRadius: '8px',
                boxShadow: 'rgba(255, 255, 255, 0.075) 0px 10px 56',
                cursor: 'pointer',
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}>
                    <a href={link} target="_blank">
                        <img width="820px" height={height} src={imgSrc} style={{borderRadius: '10px'}} />
                    </a>
                </div>
            </div>
            {/* DESC */}
            <div style={{
                margin: '10px auto',
                width: '70%',
                display: 'flex',
                justifyContent: 'center',
                textAlign: 'center',
                opacity: 0.8,
                fontFamily: '"Montserrat, sans-serif !important"',
            }}>
                {desc}
                
            </div>
        </div>
    )
}