import React from "react";
import { motion } from 'framer-motion';
import { Box } from "@mui/material";
import Typewriter from "typewriter-effect";
import CenterMode from '../Carousels/FuseFrenzyCarousel';
import nightBackground from '../../assets/black_night.jpg'
import Sun from '../Planet';
import BasicTabs from '../TabPanels/PulsiHeartTabPanel';
import pulsiHeartVideo from '../../assets/images/pulsiHeart/pulsiHeart_video.mp4'
import "../Carousel.css";
import Planet from "../Planet";

export default ({ isVisible, handleCarouselClick }) => {

    // Create item handle function
    const handleItemClick = (event) => {
        if (event.target === event.currentTarget) {
            handleCarouselClick('PulsiHeart')
        }
    }

    let greenGlow = "0 0 40px #fff, -10px 0 90px #32cd32, 10px 0 50px #0077be, inset 0 0 30px 0 #fff, inset 20px 0 30px #32cd32, inset -20px 0 30px #800080, inset 20px 0 300px #40e0d0, inset -20px 0 300px #800080, 0 0 50px #fff, -10px 0 100px #32cd32, 10px 0 80px #800080";

    return (
        <div 
            onClick={handleItemClick}
            style={{
                color: '#fff',
                fontFamily: 'Montserrat, sans-serif',
                position: "absolute",
                top: "0px",
                left: "0px",
                width: "100vw",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(43, 43, 43, 0.667)",
                zIndex: 100,
                pointerEvents: isVisible ? 'auto' : 'none',
                opacity: isVisible ? 1 : 0,
                transition: 'opacity 0.25s ease-in-out'
        }}>
            <div className="no-scrollbar" style={{
                width: "clamp(50%, 1200px, 95%)",
                height: "clamp(60%, 700px, 95%)",
                overflow: "hidden scroll",
                backgroundImage: `url(${nightBackground})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                borderRadius: "16px",
                boxShadow: "rgba(46, 139, 87, 0.35) 0px 0px 56px",
                position: 'relative',
            }}>
                {/* PLANET */}
                <Planet
                    x={200}
                    y={150}
                    glow={greenGlow}
                    size={65}
                />

                <div className='no-scrollbar' style={{
                    display: "flex",
                    padding: "100px",
                    overflowY: 'scroll',
                    flexDirection: "column",
                }}>
                    
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        // overflow: "hidden scroll",
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}>
                        {/* TITLE */}
                        <div style={{
                            marginTop: '100px',
                            fontSize: '48px',
                            fontWeight: 620,
                            lineHeight: '150%'
                        }}>
                            PulsiHeart
                        </div>
                        {/* DESC */}
                        <div style={{
                            fontSize: '16px',
                            marginTop: '20px',
                            lineHeight: '150%',
                            width: '70%',
                            textAlign: 'center'
                        }}>
                            PulsiHeart is a photoplethysmography (PPG) sensor I engineered to monitor changes in blood volume, thereby providing an estimation of a person's heart rate. The system architecture involves a PPG sensor interfaced with an Arduino board and a microcontroller. The implementation of this device required a foundation of programming in C++, which was used to manage the sensor's data and interactions.

                            <br/><br/>Additionally, a connection was established with a Python-based graphical user interface (GUI) to visualize the heart rate data. This interface also includes a feature that issues alerts for potential cardiac arrhythmias, providing critical health insights to the user. The PulsiHeart is an innovative blend of hardware and software, delivering a compact and powerful tool for heart health monitoring.
                        </div>
                        {/* CAROUSEL */}
                        <motion.div
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={isVisible ? { scale: 1, opacity: 1 } : { scale: 0.85, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12, delay: 0.2 }} 
                        >
                            <div style={{
                                width: '890px',
                                height: '620px',
                                opacity: 1,
                                transform: 'none',
                                padding: '50px 0'
                            }}>
                                {/* VIDEO */}
                                <video width='100%' height='100%' controls muted>
                                    <source src={pulsiHeartVideo} type="video/mp4" />
                                    Your browser does not support the video tag
                                </video>
                            </div>
                        </motion.div>
                        {/* Tab Panel */}
                        <div>
                            <BasicTabs />
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
