import { useState } from 'react'
import { Box } from "@mui/material";
import Planet from '../components/Planet';
import Advance from '../components/overlays/Advance';
import Echo from '../components/overlays/Echo';
import TypeKing from '../components/overlays/TypeKing';
import FuseFrenzy from '../components/overlays/FuseFrenzy';
import PulsiHeart from '../components/overlays/PulsiHeart';
import Title from '../components/Title';
import nightBackground from '../assets/black_night.jpg'
import nightStars from '../assets/black.jpeg';
import { SwiperSlide } from 'swiper/react';
import { MotionValue, motion } from "framer-motion";
import CenterMode from '../components/Carousels/ProjectCarousel';
import CenterMode2 from '../components/Carousels/SideProjectCarousel';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

export default () => {

    // Sun
    let Sun = "inset 0 0 30px 0 #fff, inset 20px 0 30px #ffd400, inset -20px 0 30px red, inset 20px 0 300px #ff40d6, inset -20px 0 300px #e3ff4b, 0 0 50px #fff, -10px 0 100px #fbff00, 10px 0 80px #F86"

    // Handle Project Overlays
    const [isAdvanceVisible, setIsAdvanceVisible] = useState(false)
    const [isEchoVisible, setIsEchoVisible] = useState(false)
    const [isTypeKingVisible, setIsTypeKingVisible] = useState(false)
    const [isFuseFrenzyVisible, setIsFuseFrenzyVisible] = useState(false)
    const [isPulsiHeartVisible, setIsPulsiHeartVisible] = useState(false)

    // Carousel Click Handler Function
    const handleCarouselClick = (componentName) => {
        switch(componentName) {
            case 'Advance':
                setIsAdvanceVisible(prevState => ! prevState);
                break;
            case 'Echo':
                setIsEchoVisible(prevState => ! prevState)
                break;
            case 'TypeKing':
                setIsTypeKingVisible(prevState => ! prevState)
                break;
            case 'FuseFrenzy':
                setIsFuseFrenzyVisible(prevState => ! prevState)
                break;
            case 'PulsiHeart':
                setIsPulsiHeartVisible(prevState => ! prevState)
                break;
            default:
                break;
        }
    }

    return (
        <>
            <Advance 
                isVisible={isAdvanceVisible}
                handleCarouselClick={handleCarouselClick} 
            />
            <Echo 
                isVisible={isEchoVisible}
                handleCarouselClick={handleCarouselClick} 
            />
            <TypeKing 
                isVisible={isTypeKingVisible}
                handleCarouselClick={handleCarouselClick} 
            />
            <FuseFrenzy
                isVisible={isFuseFrenzyVisible}
                handleCarouselClick={handleCarouselClick} 
            />
            <PulsiHeart
                isVisible={isPulsiHeartVisible} 
                handleCarouselClick={handleCarouselClick} 
            />

            <Planet
                x={1200}
                y={100} 
                glow={Sun}
            />
            <Box sx={{
                width: "100vw",
                height: "100vh",
                backgroundImage: `url(${nightStars})`,
                // backgroundImage: `url('https://thumbs.gfycat.com/MagnificentJoyousEyra-size_restricted.gif')`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                // overflow: 'hidden scroll',
            }}>
                <Box sx={{
                    width: "75%",
                    height: "75%",
                    // border: '1px solid blue',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center',
                    fontSize: '32px',
                    color: '#f3f7ff',
                    position: 'relative',
                    // overflow: 'hidden scroll',
                }}>
                    {/* Title */}
                    <Title>
                        Projects
                    </Title>
                    {/* Project Carousel */}
                    {/* <Box sx={{ */}
                    <Box className="no-scrollbar" sx={{
                        height: '100%',
                        width: '100%',
                        // border: '1px solid red',
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                        overflow: 'hidden scroll',
                    }}>
                        <Box sx={{
                            // border: '1px solid blue',
                            height: '350px',
                            width: '1100px',
                            marginTop: '300px',
                            fontFamily: 'Montserrat, sans-serif',
                        }}>
                            <CenterMode handleCarouselClick={handleCarouselClick}/>
                        </Box>
                        {/* OTHER PROJECTS */}
                        <motion.div
                            initial={{ scale: 0.85, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.85, opacity: 0 }}
                            transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }} 
                        >
                            <Box sx={{
                                fontSize: '20px',
                                fontWeight: 700,
                                marginBottom: '6px',
                                marginTop: '20px',
                                textAlign: 'center'
                            }}>
                                Other Miscellaneous Projects
                            </Box>
                            <Box sx={{
                                color: 'rgb(184, 184, 184)',
                                fontSize: '13px',
                                textAlign: 'center'
                            }}>
                                Projects completed as part of Uni coursework, as well as personal projects
                            </Box>
                        </motion.div>
                        {/* SideProject Carousel */}
                        <Box sx={{
                            height: '100%',
                            width: '100%',
                            // border: '1px solid red',
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}>
                            <Box sx={{
                                // border: '1px solid blue',
                                height: '350px',
                                width: '1100px',
                                marginTop: '10px',
                                fontFamily: 'Montserrat, sans-serif',
                            }}>
                                <CenterMode2 handleCarouselClick={handleCarouselClick}/>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    )
}
