import React, { Component } from "react";
import { MotionValue, motion } from "framer-motion";
import Slider from "react-slick";
import Item from "../ProjectItem";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import "../Carousel.css";
import { MotionConfig } from "framer-motion";
import advance_img from '../../assets/images/advance/advance_home.png'
import echo_img from '../../assets/images/echo/echo_home.png'

export default class CenterMode extends Component {

  // Create handle itme function -> handleCarouselClick
  handleItemClick = (itemTitle) => {
    const { handleCarouselClick } = this.props
    if (handleCarouselClick) {
      handleCarouselClick(itemTitle)
    }
  }

  render() {
    const settings = {
      className: "center",
      centerMode: true,
      infinite: true,
      centerPadding: "-50px",
      slidesToShow: 3,
      speed: 200,
      autoplay: true,
      autoplaySpeed: 500,
      pauseOnDotsHover: true,
      pauseOnFocus: true,
      speed: 2500
    };

      // Planet Glow Colors

      // Mercury
      let Mercury = '0 0 15px rgba(240, 241, 244, 0.35), 0 0 25px rgba(240, 241, 244, 0.15), 0 0 35px rgba(240, 241, 244, 0.1)';
      // Venus
      let Venus = '0 0 15px rgba(255, 215, 0, 0.35), 0 0 25px rgba(255, 215, 0, 0.15), 0 0 35px rgba(255, 215, 0, 0.1)';
      // Earth
      let Earth = '0 0 15px rgba(70, 130, 180, 0.4), 0 0 25px rgba(70, 130, 180, 0.2), 0 0 35px rgba(70, 130, 180, 0.2)';
      // Mars
      let Mars = '0 0 15px rgba(255, 99, 71, 0.30), 0 0 25px rgba(255, 99, 71, 0.15), 0 0 35px rgba(255, 99, 71, 0.1)';
      // Jupiter
      let Jupiter =  '0 0 15px rgba(244, 164, 96, 0.35), 0 0 25px rgba(244, 164, 96, 0.15), 0 0 35px rgba(244, 164, 96, 0.1)';
      // Neptune
      let Neptune =  '0 0 15px rgba(135, 206, 250, 0.35), 0 0 25px rgba(135, 206, 250, 0.15), 0 0 35px rgba(135, 206, 250, 0.1)';

    return (
      <div>
        <motion.div
          initial={{ scale: 0.85, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.85, opacity: 0 }}
          transition={{ type: 'spring', bounce: 0.6, stiffness: 150, damping: 12 }} 
        >
          <Slider 
          {...settings}
          >
            <div onClick = {() => this.handleItemClick('Advance')}>
              <Item imgSrc={advance_img} title="Advance" desc="Empower Education, Both Onsite and Online." color={true} glow={Mercury}/>
            </div>
            <div onClick = {() => this.handleItemClick('Echo')}>
              <Item imgSrc={echo_img} height="140px" title="Echo" desc="Promoting economic and financial literacy." color={true} glow={Earth}/>
            </div>
            <div>
              <Item imgSrc="" title="" desc="" color={true} glow={Venus}/>
            </div>
            <div>
              <Item imgSrc="" title="" desc="" color={true} glow={Mars}/>
            </div>
          </Slider>

        </motion.div>
      </div>
    );
  }
}